import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 7000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>

                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            "My son has a blast playing at WRTS Fenton! His favorite part is the swing, but he loved many of the activities the gym had to offer! I love that there is a place in town that is so neurodivergent friendly as my son is on the spectrum. I wish there were more places like it! We will definitely be back to play soon. Just wanted to edit to add that my husband brought him back to play again today, and they both had a wonderful time together! This time he is loving the slide. We can’t say enough good." </p>
                            <p className="overview">- Miranda A.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “My two year old LOVES we rock the spectrum! So many activities, keeps him busy for hours if needed. Some of his favorites are the green lawnmower, the ballpit slide, and the playhouse. I’m excited to see him grown into other activities like the zip line. I can see us using this space for a birthday party in the near future.
                             </p>
                            <p className="overview">- Melissa H. </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “This is such a fun and welcoming space! We've taken our 6yo for years and it’s been our go to for indoor play when his cousins come to visit from out of state.  They have a blast being physically active and getting sensory needs met while being welcomed for exactly who they are. We had his birthday party there last year and it was PERFECT!" 
                            </p>
                            <p className="overview">
                            - Sarah E.
                            </p>
                        </div>

                         <div className="item carousel-item">
                            <p className="testimonial">
                            “We love coming here ! Our son is 2 and the staff here is incredible every time we come in! So much to keep kids of all ages busy! It’s very clean and organized and always looking for ways to improve." </p>
                            <p className="overview">
                            - Danielle B.
                            </p>
                        </div> 
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “WRTS is a great place to host a birthday party! We had our sons 3rd birthday there. Everyone had an awesome time- the party room was a great  for cake and presents and the kids had so much fun burning off energy! We had kids ranging from age 2-12. They all had a blast!”</p>
                            <p className="overview">- Stephanie G.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “We’ve gone to two birthday parties here and there’s so much to do for kiddos of different ages. My 10 month old and 5 year old were both able to have loads of fun but I was able to keep an eye on both so I didn’t have to worry about running after each one. Also, they have a calming room that’s perfect for babies or older kids that need a second to regroup. Can’t wait to come during open play!”</p>
                            <p className="overview">- Courtney P.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “Great place for kids!! My nephew, who is on the spectrum, had a great time yesterday!! He loved the trampoline, swing and zip line! Staff was super friendly and helpful.”
                            </p>
                            <p className="overview">
                            - Christy H.
                            </p>
                        </div>


                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
